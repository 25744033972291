import {RouterModule, Routes} from '@angular/router';
import {DigitalLibraryComponent} from './components/digital-library/digital-library.component';
import {AboutComponent} from './components/about/about.component';
import {NewsComponent} from './components/news/news.component';
import {PortfolioDetailComponent} from './components/portfolio-detail/portfolio-detail.component';
import {RentRoomComponent} from './components/rent-room/rent-room.component';
import {ModuleWithProviders} from '@angular/core';
import {FaqComponent} from './components/faq/faq.component';
import {FormsComponent} from './components/forms/forms.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {PortfolioMk2Component} from './components/portfolio-mk2/portfolio-mk2.component';
import {EtdPageComponent} from './components/etd-page/etd-page.component';
import {DataPageComponent} from './components/data-page/data-page.component';

export const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'dashboard', component: DashboardComponent, data: {pageTitle: 'dashboard'}, pathMatch: 'full'},
  {path: 'digitalLibrary', component: DigitalLibraryComponent, data: {pageTitle: 'digitalLibrary'}, pathMatch: 'full'},
  {path: 'about', component: AboutComponent, data: {pageTitle: 'about'}, pathMatch: 'full'},
  {path: 'news', component: NewsComponent, data: {pageTitle: 'news'}, pathMatch: 'full'},
  {path: 'portfolio', component: PortfolioMk2Component, data: {pageTitle: 'portfolio'}},
  {path: 'rent-room', component: RentRoomComponent, data: {pageTitle: 'rentRoom'}},
  {path: 'etd', component: EtdPageComponent, data: {pageTitle: 'ETD'}},
  {path: 'faq', component: FaqComponent, data: {pageTitle: 'FAQ'}},
  {path: 'portfolio-detail/:type', component: PortfolioDetailComponent, data: {pageTitle: 'portfolioDetail'}},
  {path: 'forms/:type', component: FormsComponent, data: {pageTitle: 'forms'}},
  {path: 'data/:file', component: DataPageComponent, data: {pageTitle: 'data'}},
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { useHash: true });
