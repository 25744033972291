import {Component, OnInit} from '@angular/core';
import {PortfolioCategoryEnum} from '../../enums/portfolio-category.enum';
import {PortfolioItems} from './data/portfolio-items';
import {PortfolioFilterCategories} from './data/portfolio-filter-categories';
import {animate, animateChild, query, stagger, style, transition, trigger} from '@angular/animations';
import {listAnimationContainer, listAnimationItem} from '../../animations/list-animation';
import {Router} from '@angular/router';
import {ALFRED_URL} from '../../app.urls';
import {AppCommunicationService} from '../../services/app-communication.service';
import {HttpClient} from '@angular/common/http';
import {PortfolioItemModel} from '../portfolio-detail/portfolio-item.type';
import {PRODUCTION_BUILD} from '../../app.settings';

export const AllFilterType = 'Všetko';

@Component({
  selector: 'app-portfolio-mk2',
  templateUrl: './portfolio-mk2.component.html',
  styleUrls: ['./portfolio-mk2.component.scss'],
  animations: [
    listAnimationContainer,
    listAnimationItem
  ]
})
export class PortfolioMk2Component implements OnInit {

  portfolioCategories: string[] = [];

  currentFilter = null;

  filters: string[] = [
    AllFilterType
  ];

  portfolio: PortfolioItemModel[] = [];

  filteredItems: PortfolioItemModel[] = [];

  imagePortfolioUrl = PRODUCTION_BUILD ? 'https://storage.service.tuke.sk/libImages/portfolio/' : 'https://storage.service.dev.msweb.tuke.sk/libImages/portfolio/';

  constructor(
    private router: Router,
    private appService: AppCommunicationService,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.getData();

  }

  changedFilter(filter: string) {
    this.currentFilter = filter;
    this.filterData();
  }

  filterData() {
    if (this.currentFilter === AllFilterType) {
      this.filteredItems = [...this.portfolio];
    } else {
      this.filteredItems = this.portfolio.filter(p => p.type.toLowerCase().includes(this.currentFilter.toLowerCase()));
    }
  }

  openPortfolioDetail(portfolioItem) {
    if (!portfolioItem || !portfolioItem.id) {
      return;
    }
    this.router.navigate([`/portfolio-detail/${portfolioItem.id}`, {item: JSON.stringify(portfolioItem)}]);
  }

  getData() {
    this.appService.loadingOn();

    this.http
      .post(`${ALFRED_URL}/portfolio/anonymous/GetAllByFilter`, {
        pageSize: 100
      })
      .subscribe({
        next: value => {
          value['items'].forEach(i => {
            const main = i.images.find(img => img.isMain);

            if (main) {
              i['mainImageUrl'] = main.url;
            }
          });

          this.portfolio = value['items'];


          this.portfolio.sort((a, b) => {
            return a.title.trim().toLowerCase().localeCompare(b.title.trim().toLowerCase());
          });

          this.filteredItems = [...this.portfolio];

          this.portfolioCategories = this.filteredItems.map(i => i.type.toUpperCase());

          this.portfolioCategories = [...new Set(this.portfolioCategories)];

          this.filters = [...this.filters, ...this.portfolioCategories];

          this.appService.loadingOff();
        },
        error: err => {
          console.log(err);
          this.appService.loadingOff();
        }
      });
  }
}
