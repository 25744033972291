import { Component } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FileService} from '../../services/file.service';
import {first} from 'rxjs';
import {PRODUCTION_BUILD} from '../../app.settings';
import {AppCommunicationService} from '../../services/app-communication.service';

@Component({
  selector: 'app-data-page',
  standalone: true,
  imports: [],
  templateUrl: './data-page.component.html',
  styleUrl: './data-page.component.scss'
})
export class DataPageComponent {

  file = '';

  API_FILE_DOWNLOAD_URL = PRODUCTION_BUILD ? 'https://api.alfred.portal.tuke.sk/api/file/' : 'https://api.alfred.portal.dev.msweb.tuke.sk/api/file/';

  constructor(
    private activatedRoute: ActivatedRoute,
    private fileService: FileService,
    private loading: AppCommunicationService
  ) {

    this.extractFileFromUrl();

  }

  extractFileFromUrl() {
    // extract file from url
    // it is as file parameter in url
    this.activatedRoute.params.subscribe(params => {

      if (params && params.file) {
        this.file = params.file;

        this.downloadFile(this.file);
      }

    });
  }

  downloadFile(file: string) {
    // download file
    console.log('Downloading file:', file);
    this.loading.loadingOn();

    this.fileService
      .download(this.API_FILE_DOWNLOAD_URL + file)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.loading.loadingOff();
        },
        error: (error) => {
          this.loading.loadingOff();
        }
      });
  }

}
