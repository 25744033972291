import {Component, OnInit} from '@angular/core';
import {PortfolioItemModel} from './portfolio-item.type';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {PRODUCTION_BUILD} from '../../app.settings';

@Component({
  selector: 'app-portfolio-detail',
  templateUrl: './portfolio-detail.component.html',
  styleUrls: ['./portfolio-detail.component.scss']
})
export class PortfolioDetailComponent implements OnInit {

  item: PortfolioItemModel;

  priceDescriptions = [];

  currentImageIndex = 0;

  imagePortfolioUrl = PRODUCTION_BUILD ? 'https://storage.service.tuke.sk/libImages/portfolio/' : 'https://storage.service.dev.msweb.tuke.sk/libImages/portfolio/';

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected http: HttpClient,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.activatedRoute
      .params
      .subscribe(params => {
        if (params.item) {
          this.item = JSON.parse(params.item);

          this.reorderImages();

          if (this.item && this.item.priceDescriptions.length > 0) {
            this.priceDescriptions = this.item.priceDescriptions.split(',');
          }
        }
      });

    setTimeout(() => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }, 150);
  }

  reorderImages() {
    this.item.images = this.item.images.sort((a, b) => {
      return a.orderNumber - b.orderNumber;
    });
  }

  goToPortfolio(e: Event) {
    e.preventDefault();
    this.router.navigate(['/portfolio']);
  }

  onImageChange(e) {
    if (e && e.page) {
      this.currentImageIndex = e.page;
    }
  }
}
